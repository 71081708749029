<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />

    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <img
        src="../assets/Dtech_cores.png"
        height="60"
        class="c-sidebar-brand-full mb-2 mt-2"
        alt="Logo"
      />
    </CHeaderBrand>

    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
//import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: "TheHeader",
  components: {
    //TheHeaderDropdownAccnt
  },
};
</script>
