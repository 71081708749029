export default [
  {
    _name: 'CSidebarNav',
    _children: [
      
      {
        _name: 'CSidebarNavItem',
        name: 'Users',
        to: '/dashboard',
        // icon: 'cil-speedometer' ,
        icon: 'cil-user' ,
      
      },
      {
        _name: 'CSidebarNavDivider',
        _class: 'm-2'
      },
      
      {
        _name: 'CSidebarNavTitle',
        _children: ['Services']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'MeetUps',
        to: '/meetups',
        icon: 'cil-puzzle',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Speakers',
        to: '/speakers',
        icon: 'cil-cursor',
  
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Schedule',
        to: '/schedule',
        icon: 'cil-chart-pie'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Sponsors',
        to: '/sponsors',
        icon: 'cil-star',
      
      },
      {
        _name: 'CSidebarNavDivider',
        _class: 'm-2'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Logout',
        to: '/logout',
        icon: 'cil-account-logout'
      },
    ]
  }
]